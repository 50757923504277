<template>
    <div class="item" @click="viewLoadDetails">
        <div class="loadNumber">#{{ load.loadID }}</div>
        <div class="date">{{ pickup }}</div>
        <div class="origin">{{ load.origin }}</div>
        <div class="destination">{{ load.destination }}</div>
        <div class="weight"><span v-if="load.weight">{{ priceConverter(load.weight) }} <strong>lbs</strong></span></div>
        <div class="length"><span v-if="load.length">{{ load.length }} <strong>ft</strong></span></div>
        <div class="status textNoWrap" v-if="['pickedup', 'delivered', 'pending', 'delayed'].includes(load.status)">
            <select v-model="load.status" :class="load.status" @click.stop>
                <option :value="item.value" :key="key" v-for="(item, key) of statuses">{{ item.name }}</option>
            </select>
        </div>
        <div class="status" :class="status.class" v-else>{{ status.name }}</div>
    </div>

</template>

<script>
import moment from 'moment'

    export default {
        props: ['load'],
        data() {
            return {
                statuses: [
                    { name: 'Pending', value: 'pending' },
                    { name: 'Picked up', value: 'pickedup' },
                    { name: 'Delivered', value: 'delivered' },
                    { name: 'Delayed', value: 'delayed' },
                ]
            }
        },
        methods: {
            viewLoadDetails() {
                this.$store.state.loadManager.viewLoad = this.load._id;
            }
        },
        watch: {
            "load.status"() {
                this.$emit('status', this.load.status);
            }
        },
        computed: {
            driver() {
                if(!this.load.deliveryDriver) return null;
                if(!this.load.deliveryDriver[0]) return null;
                return this.load.deliveryDriver[0];
            },
            status() {
                let status = this.load.status;
                switch (status) {
                    case 'canceled':
                        return { class: 'cancelled', name: 'Cancelled' }
                        break;
                    case 'pickedup':
                        return { class: 'pickedup', name: 'Picked up' }
                        break;
                    default:
                        return { class: 'pending', name: 'Pending' }
                        break;
                }
            },
            pickup() {
                let date = moment.utc(this.load.date, "MM/DD/YYYY");
                return date.format("D MMM");
            },
            weight() {
                try {
                    let value = Number(this.load.weight);
                    if(isNaN(value)) {
                        throw new Error();
                    }
                    return this.priceConverter(value) + " lbs";
                } catch(e) {
                    return this.load.weight;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>


.item {
    position: relative;
    background: $bgSecondary;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 75px 50px minmax(0, 1fr) minmax(0, 1fr) 100px 75px 120px 75px 75px;
    font-size: 13px;
    width: 1160px;
    &:hover {
        z-index: 1;
        box-shadow: $boxShadow;
    }
}

.rate {
    font-weight: bold;
}


.status {
    font-size: 12px;
    select {
        width: 100%;
        height: 28px;
        border-radius: 5px;
        border: 1px solid $borderColor;
        color: $text;
        padding: 0 5px;
        font-size: 12px;
        color: $text;
        &.pickedup {
            color: $buttonText;
            background: $buttonColor;
        }
        &.delivered {
            background: $success;
            color: $buttonText;
        }
        &.delayed {
            background: rgb(247, 220, 111);
            color: $text;
        }
    }
    &.pending {
        color: #717D7E;
    }
    &.pickedup {
        color: $buttonColor;
    }
    &.cancelled {
        color: #C0392B;
    }
}

.date {
    font-weight: bold;
}

</style>